import { Button } from 'antd';
import React, { useEffect, useRef, useState } from 'react';

import styled from 'styled-components';
import RightArrow from './RightArrow';
import LeftArrow from './LeftArrow';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import useViewport from './useViewport';

const SliderContainer = styled.div`
  position: relative;
  overflow: hidden;
  top: 50px;
  
  max-width: ${props => props.maxWidth}px;
  width: 100%;
  min-height:${props => props.height || 750}px;
  background:rgb(11,10,10);
  @media (min-width: ${props => props.maxWidth}px) {
    margin: 0 auto;
  }
`

const SliderItems = styled.div`
  width:  100%;
  overflow-x: hidden;
  display: flex;
  background:rgb(11,10,10);
  align-items:center;
  position:relative;
  min-height: inherit
`

const SliderImage = styled.img`
  width: ${props => props.width || 620}px;
  height: ${props => props.height || 447}px;
  transition: transform 0.5s, right 0.5s, opacity 0.5s;
  margin: 0px 90px;
  border-radius:10px;
  position:absolute;
  @media (max-width: 991px) {
    margin: 0px;
    width: 90%;
    height: auto;
  }
`

const SliderNavLeft = styled.div`
  background:#1890ff;
  border-color:#1890ff;
  border-radius: 8px;
  z-index:3;
  :hover{
    background:white;
    border-color:white;
  }
`

const SliderNavRight = styled.div`
  background:#1890ff;
  border-color:#1890ff;
  border-radius: 8px;
  z-index:3;
  :hover{
    background:white;
    border-color:white;
  }
`

const NavButtonStyles = {
    width: "68px",
    height: "68px",
    paddingTop: "13px",
    borderRadius: "8px",
    background: "inherit",
    borderColor: "inherit",
    zIndex: 5
}

const HideArrowDiv = styled.div`
  zIndex: 5;
  position: absolute;
  left: ${props => props.direction === "left" ? -15 + "px" : "none"};
  right: ${props => props.direction === "right" ? -15 + "px" : "none"  };
  @media (max-width: 991px) {
    display:none;
  }
`

const RadioControl = styled.div`
  margin:35px auto;
  display:flex;
  gap:20px;
  justify-content:center;
  padding:10px 0px 50px;
`

const RadioDiv = styled.div`
  cursor:pointer;
  width:10px;
  height:10px;
  box-shadow: ${ props => props.selected ? "0px 0px 8px 2px rgba(66, 133, 244, 0.2)" : "0px 0px 8px 2px rgba(0, 0, 0, 0.2)" };
  background:${ props => props.selected ? "#4285F4" : "#373333" };
`

const TestDiv = styled.div`
  position:absolute;
  right:${props => props.minDx + 90 - (props.width * 1.4 - props.width) / 2}px;
  width:${props => props.width * 1.4}px;
  display: flex;
  align-items:center;
  z-index:100;
`

const calcMinDx = width => {
    return 180;
    /*
    if(width > 2000) return width * 0.336
    else if(width > 1800) return width * 0.234;
    else if(width > 1200) return 180;
    else if(width < 1200) return 110;
    */
}
let minDx = 180;
const Carousel = ({maxWidth = 2000, items}) => {
    const screens = useBreakpoint();
    let dx = 785;

    const renderSlider = () => {
        sliderRef.current.childNodes.forEach((el, count) => {
            let width = window.innerWidth;
            if(width > maxWidth) width = maxWidth;
            if(el.tagName === "DIV") return;
            minDx = calcMinDx(width);

            if(width < 992) {
                dx *= 2;
                el.style = `right: ${ count === selectedItem ? width * 0.1 / 2 : dx * selectedItem - dx * count}px; ${count === selectedItem ? 'z-index:2' : ''}`;
            }
            else el.style = `right: ${(dx * selectedItem - dx * count) + minDx}px; ${count === selectedItem ? 'transform:scale(1.4); z-index:2' : 'opacity: 0.4'}`;
        })
    }

    useEffect(() => {
        sliderRef.current.childNodes.forEach((el, count) => {
            let width = window.innerWidth;
            if(width > maxWidth) width = maxWidth;
           
            if(el.tagName === "DIV") return;
            minDx = calcMinDx(width);

            if(width < 992) {
                el.style = `right: ${ count === selectedItem ? width * 0.1 / 2 : 1200 * selectedItem - 1200 * count}px; ${count === selectedItem ? 'z-index:2' : ''}`;
            }
            else el.style = `right: ${(dx * selectedItem - dx * count) + minDx}px; ${count === selectedItem ? 'transform:scale(1.4); z-index:2' : 'opacity: 0.4'}`;
        })
        window.addEventListener("resize", renderSlider);
        return () => window.removeEventListener("resize", renderSlider);
    })

    const sliderRef = useRef(null);
    const [selectedItem, setSelectedItem] = useState(1);
    const [hoverArrow, setHoverArrow] = useState(null);
    const [touchStart, setTouchStart] = useState(null);
    const [touchMove, setTouchMove] = useState(null);
    let { width } = useViewport();
    if(width === 1600 && typeof window !== "undefined") width = window.innerWidth;
    if(width > maxWidth) width = maxWidth;
    
    let sliderImageWidth = Math.round(width / 2.58);
    if(sliderImageWidth > 620) sliderImageWidth = 620;
    const koefHeight = 0.72;
    dx = sliderImageWidth * 1.265;

    const sliderImageHeight = sliderImageWidth * koefHeight;
    minDx = calcMinDx(width);
    if(width < 992) dx *= 2.5;


    const nextHandler = () => {
        if(selectedItem === items.length - 1) return;
        sliderRef.current.childNodes.forEach((el, count) => {
            if(el.tagName === "DIV") return;
            el.style = `right: ${(dx * (selectedItem + 1) - count * dx) + minDx}px; ${count === selectedItem + 1 ? 'transform:scale(1.4); z-index:2' : 'opacity: 0.4'}`;
        })

        setSelectedItem(selectedItem + 1)
    }

    const prevHandler = () => {
        if(selectedItem === 0) return;
        sliderRef.current.childNodes.forEach((el, count) => {
            if(el.tagName === "DIV") return;
            el.style = `right: ${(dx * (selectedItem - 1) - count * dx) + minDx}px; ${count === selectedItem - 1 ? 'transform:scale(1.4); z-index:2' : 'opacity: 0.4'}`;
        })
        setSelectedItem(selectedItem - 1)
    }

    const handleRadioClick = (index) => {
        sliderRef.current.childNodes.forEach((el, count) => {
            if(el.tagName === "DIV") return;
            if(!screens.lg) el.style = `right: ${ count === index ? width * 0.1 / 2 : 1200 * index - 1200 * count}px; ${count === index ? 'z-index:2' : ''}`;
            else el.style = `right: ${(dx * index - count * dx) + minDx}px; ${count === index ? 'transform:scale(1.4); z-index:2' : 'opacity: 0.4'}`;
        })
        setSelectedItem(index)
    }

    const handleMouseEnter = direction => {
        setHoverArrow(direction);
    }

    const handleMouseLeave = () => setHoverArrow(null);

    const handleTouchEnd = () => {
        if(!touchMove) return;
        if(touchStart > touchMove){
            if(selectedItem === items.length - 1) return;
            handleRadioClick(selectedItem + 1)
        }else{
            if(selectedItem === 0) return;
            handleRadioClick(selectedItem - 1)
        }
        setTouchMove(null)
    }

    return (
        <SliderContainer maxWidth={maxWidth} height={sliderImageHeight * 1.4 + 250} onTouchStart={(e) => setTouchStart(e.touches[0].pageX)} onTouchMove={(e) => setTouchMove(e.touches[0].pageX)} onTouchEnd={handleTouchEnd} >
            <SliderItems ref={sliderRef}>

                {
                    items.map((item, count) =>
                        <SliderImage height={sliderImageHeight} width={sliderImageWidth} key={count} src={ item.localFile.publicURL } />
                    )
                }

                <TestDiv minDx={minDx} width={sliderImageWidth}>
                    {
                        items && selectedItem !== 0 &&
                        <HideArrowDiv direction="left"  width={sliderImageWidth}>
                            <SliderNavLeft onClick={prevHandler}>
                                <Button type="primary" onMouseEnter={() => handleMouseEnter("left")} onMouseLeave={handleMouseLeave} size="large" style={NavButtonStyles}>
                                    <LeftArrow color={ hoverArrow === "left" && "#4285F4" } />
                                </Button>
                            </SliderNavLeft>
                        </HideArrowDiv>
                    }
                    
                    {
                        items && selectedItem !== items.length - 1 && 
                        <HideArrowDiv direction="right" width={sliderImageWidth}>
                            <SliderNavRight onClick={nextHandler}>
                                <Button type="primary" onMouseEnter={() => handleMouseEnter("right")} onMouseLeave={handleMouseLeave} size="large" style={NavButtonStyles}>
                                    <RightArrow color={ hoverArrow === "right" && "#4285F4" } />
                                </Button>
                            </SliderNavRight>
                        </HideArrowDiv>
                    }
                    
                </TestDiv>
            </SliderItems>

            {
                !screens.lg &&
                <RadioControl>
                    {
                        items.map((item, count) =>
                            <RadioDiv selected={count === selectedItem} key={count} onClick={ () => handleRadioClick(count) } />
                        )
                    }
                </RadioControl>
            }


        </SliderContainer>
    )
}

export default Carousel;
