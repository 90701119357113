import React, { useState } from "react";
import styled from "styled-components";

import Plus from "../assets/plus.svg";
import Header from "../components/Header/header";
import Footer from "../components/Footer/footer";
import Category from "../components/Category";
import Card from "../components/Cards";
import Carousel from "../components/Carousel/Carousel";
import FormBlock from "../components/Form";
import Seo from "../components/seo";
import SliderCard from "../components/Slider/SliderCard";
import "../components/layout.css";
import { ContainerCarousel, ContainerL } from "../ui/containers";
import Heading from "../ui/heading";
import Modal from "../ui/modal";
const PlusText = styled.div`
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */
  margin-left: 14px;
  margin-top: 17px;
  text-transform: uppercase;
  color: #FFFFFF;
`;

const Otstup = styled.div`
  margin-top: 110px;
  @media (max-width: 568px) {
    margin-top: 45px;
  }
`;

const OtstupFooter = styled.div`
  margin-bottom: 120px;
  margin-top: 120px;
  @media (max-width: 568px) {
    margin-bottom: 320px;
    margin-top: 140px;
  }
`;

const ContainerR = styled.div`
  padding-left: 150px;
  max-width: 1600px;
  width: 100%;
  @media (max-width: 1268px) {
    padding: 10px;
  }
`;


const Service = ({ pageContext }) => {
  const [openModal, setOpenModal] = useState(false);
  const {cards, carousel,devices} = pageContext;
  const data = cards.map(item => {
    return ({
      title: item.title,
      text: item.description,
      imgUrl: item.image.localFile,
    })
  })
  const toggleOpenModal = () => {
    if (!openModal) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "auto";
    setOpenModal(!openModal);
  };


  return (
    <>
      <Seo title={pageContext.title} />
      <Header />
      {
        openModal ?
          (<Modal toggleOpenModal={toggleOpenModal} />)
          :
          (
            <>
              <ContainerL style={{ margin: "0px auto 0 auto" }}>
                <Otstup/>
                <Heading title={pageContext.title}
                         buttonText={"Рассчитать стоимость"}
                         description={pageContext.Description}
                         button
                         background={pageContext.title}
                         toggleOpenModal={toggleOpenModal}
                />
                {pageContext.item && pageContext.item.length!== 0 ?
                <div style={{marginTop:140}}>
                  <Category disable vertical={true} data={pageContext.item.map((item, index) => {
                    return ({
                      name: item.title,
                      img: <img placeholder="blurred" src={item.svg["0"].localFile.publicURL} alt={index + " services"} />
                    });
                  })} />
                </div> : null}
                <div style={{ marginTop: 110 }}>
                  <Plus/>
                </div>

                <PlusText style={{ marginBottom: 150}}>
                  преимущества
                </PlusText>
                {data.map((item, index) =>
                  (<div key={index}>
                    <Card data={item} isReversed={index % 2 !== 0}/>
                  </div>))}
              </ContainerL>
              <ContainerCarousel>
                    <Carousel items={carousel} maxWidth={2000}/>
              </ContainerCarousel>
              <ContainerR style={{ margin: "160px auto 0 auto" }}>
                <SliderCard data={devices} toggleOpenModal={toggleOpenModal} title="устройства"/>
              </ContainerR>
              <OtstupFooter>
                <ContainerL style={{ marginTop: "120px", marginBottom: "120px" }}>
                  <div style={{ marginTop: 160 }}>
                    <FormBlock />
                  </div>
                </ContainerL>
                <Footer toggleOpenModal={toggleOpenModal} />
              </OtstupFooter>
            </>
          )
      }

    </>
  );
};

export default Service;
