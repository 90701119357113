import React from "react"
import {Carousel} from "antd"
import "../layout.css"
import Card from "../../ui/card";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";


const setSettings = br => {
    if (br.xxl || br.xl|| br.lg)
        return {
            infinite: false,
            dots: false,
            speed: 500,
            slidesToScroll: 1,
            slidesToShow: 3,
            draggable: true,
        }
    else return {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToScroll: 1,
        slidesToShow: 1,
        draggable: true,
    }
}

const BlockOfPictures = ({data,toggleOpenModal}) => {
    const screens = useBreakpoint();
    let settings = setSettings(screens)

    return (

        <Carousel {...settings}>
            {data.devices.map((item, index) =>
                <div key={index}>
                    <Card toggleOpenModal={toggleOpenModal} item={item}/>
                </div>
            )}
        </Carousel>

    )
}

export default BlockOfPictures
