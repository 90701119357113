import React from 'react';
import styled from "styled-components";
import Arrow from "../assets/arrowBack.svg";

import {Btn, TextButton, BtnContainer} from "../components/constants";


const Container = styled.div`
  font-weight: 200;
  font-size: 72px;
  line-height: 96px;
  text-transform: uppercase;
  color: #FFFFFF;
  @media (max-width: 568px) {
    font-weight: 200;
    font-size: 35px;
    line-height: 58px;
    text-transform: uppercase;
    color: #FFFFFF;
  }
`;


const BorderBottom = styled.div`
  width: 111px;
  margin-top: 28px;
  margin-bottom: 32px;
  border-bottom: solid 2px #4285F4;
`;


const ContentText = styled.div`
  font-size: 18px;
  line-height: 27px;
  max-width: 624px;
  color: #FFFFFF;
  @media (max-width: 600px) {
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
  }
`;

const BoldTitle = styled.span`
  font-weight: 700
`

const BackgroundText = styled.div`
  font-size: 288px;
  position: absolute;
  text-align: right;
  color: #0D0C0C;
  right: auto;
  padding-right: 320px;
  width: 1600px;
  word-break: break-word;
  margin-top: -160px;
  text-transform: uppercase;
  z-index: -9;
  font-weight: 300;
  line-height: 320px;
  text-shadow: 0px 25px 50px rgba(0, 0, 0, 0.55);

  &::selection {
    background: transparent;
    color: #0D0C0C;
  }

  &::-moz-selection {
    background: transparent;
    color: #0D0C0C;
  }
  @media (max-width: 968px){
    display:none;
  }
`;

const A = styled.a`
  transition-duration: 0.2s;
  color: inherit;
  &:hover {
    color: white;
  }
`;

const Heading = ({title, description, button, buttonText, boldTitle, background = "Климат",toggleOpenModal}) => {

    return (
      <>
        <Container>
          {title}
          {boldTitle && <BoldTitle> {boldTitle}</BoldTitle>}
          {background && <BackgroundText>{background}</BackgroundText>}
        </Container>
        <BorderBottom />
        <ContentText>{description}</ContentText>

        {button && (
          <BtnContainer>
            <Btn
              shape="circle"
              type="primary"
              size={"large"}
              onClick={toggleOpenModal}
            >
              <Arrow />
            </Btn>
            <TextButton>
              <A onClick={toggleOpenModal}>{buttonText || "Хочу также"}</A>
            </TextButton>
          </BtnContainer>
        )}
      </>
    );
}
;

export default Heading;
