import React from "react";
import "../layout.css";
import { Btn, BtnContainer, Description, InfoPage, TextButton, Title } from "../constants";
import BlockOfPictures from "./blockOfPictures";
import styled from "styled-components";
import Arrow from "../../assets/arrowBack.svg";
import { Link } from "gatsby";
import PlusComponent from "../../ui/plusComponent";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
const Index = styled.div`
  display: flex;
  flex-direction: row;
  margin: ${props => props.isReversed === "reversed" ? "auto auto 120px" : "auto auto 70px"};
  @media screen and (max-width: 1000px) {
    flex-direction: column;
    margin-bottom: 139px;
    margin-left: 10px;
    margin-right: 10px;
  }
`;

const Image = styled(GatsbyImage)`
  position: relative;
  border-radius: 16px;
  width: 620px;
  height: 620px;
  border-radius: 16px;

  opacity: 0.6;
  order: ${props => props.isReversed === "reversed" ? "1" : "2"};
  @media screen and (max-width: 1280px) {
    width: 420px;
    height: 420px;
  }
  @media screen and (max-width: 1000px) {
    width: 100%;
    height: 100%;
    order: ${props => props.cards === "cardsExist" ? "0" : "2"};
    margin-bottom: ${props => props.cards === "cardsExist" ? "37px" : ""};
  }
`;

export const TextContainer = styled.div`
  display: flex;
  padding-left: 8px;
  flex-direction: column;
  color: white;
  text-overflow: clip;
  order: ${props => props.isReversed === "reversed" ? "2" : "1"};
  margin: ${props => props.isReversed === "reversed" ? props.cards === "cardsExist" ? "20px 0 0 40px" : "73px 0 0 40px" : props.cards ? "16px 37px 0 0" : "0px 46px 0 0"};
  margin-top: ${props => props.gutter ? "90" : "40"}px;
  @media screen and (max-width: 1000px) {
    order: 1;
    margin: 0 0 40px 0;
  }

`;
const TextDate = styled.div`
  font-weight: 300;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height, or 123% */
  margin-top: 4px;
  color: #636363;
`;

const A = styled.a`
  transition-duration: 0.2s;
  color: inherit;
  &:hover {
    color: white;
  }
`;



const Card = ({
                data,
                isReversed,
                button,
                buttonText = "ПОДРОБНЕЙ",
                plus = false,
                gutter = false,
                toggleOpenModal,
                link
              }) => {
  const img = getImage(data.imgUrl)

  return (
    <InfoPage>
      <Index isReversed={isReversed ? "reversed" : ""}>
        <Image
          cards={data.cards ? "cardsExist" : ""}
          isReversed={isReversed ? "reversed" : ""}
          image={img}
          alt={"Picture" + data.title}
        />

        <TextContainer
          isReversed={isReversed ? "reversed" : ""}
          gutter={gutter}
          cards={data.cards ? "cardsExist" : ""}
        >
          {plus && <PlusComponent />}
          <Title style={{ marginBottom: data.date ? 50 : 12 }}>
            {data.title}
            <TextDate>{data.date}</TextDate>
          </Title>

          <Description>
            <div style={{ marginBottom: "67px", whiteSpace: "pre-wrap" }}>
              {data.text}
            </div>

            {data.cards ? (
              <BlockOfPictures
                toggleOpenModal={toggleOpenModal}
                data={data.cards[0]}
              />
            ) : button ? (
              <BtnContainer>
                <Btn
                  shape="circle"
                  type="primary"
                  size={"large"}
                  onClick={toggleOpenModal}
                >
                  <Arrow />
                </Btn>
                <TextButton>
                  <A onClick={toggleOpenModal}>Связаться с нами</A>
                </TextButton>
              </BtnContainer>
            ) : link ? (
              <BtnContainer>
                <Link to={`/articles/${data.link}`}>
                  <Btn
                    shape="circle"
                    type="primary"
                    size={"large"}
                    onClick={toggleOpenModal}
                  >
                    <Arrow />
                  </Btn>
                </Link>
                <TextButton>
                  <A onClick={toggleOpenModal}>
                    {buttonText ? buttonText : "Связаться с нами"}
                  </A>
                </TextButton>
              </BtnContainer>
            ) : null}
          </Description>
        </TextContainer>
      </Index>
    </InfoPage>
  );
};

export default Card;
